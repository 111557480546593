/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/prefer-default-export */

import React, {
  useCallback, useEffect, useState,
} from "react";
import {
  LivechatMessageItem, LivechatRoomInfo, LivechatRoomStatus, livechatRoomIsOpen,
} from "@audacia-hq/shared/contexts";
import { DateUtils } from "@audacia-hq/shared/utils";
import Countdown, { CountdownRendererFn } from "react-countdown";
import dayjs from "dayjs";

import Button from "../Button";
import AlertModal from "../modal/AlertModal";
import Chat from "../chat";

const livechatTimeoutDelay = 2;
interface Props {
  livechatRoom: LivechatRoomInfo;
  status: LivechatRoomStatus;
  messages: LivechatMessageItem[];
  participantsWriting: string[];
  userUid: string;
  showHeader?: boolean;
  showAttachment?: boolean;
  online: boolean;
  sendMessage: (content: string) => void;
  sendAttachment?: (file: File) => Promise<void>;
  getAttachment: (url: string) => Promise<string>;
  sendWritingStatus: (writing: boolean) => void;
  closeLivechatRoom: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: string, params?: any) => string;
  onNewMessageUnfocused?: () => void;
}

export const LivechatRoom: React.FC<Props> = ({
  livechatRoom, status, messages, participantsWriting, userUid, showHeader = true, showAttachment = true, online,
  sendWritingStatus, sendMessage, sendAttachment, getAttachment, closeLivechatRoom, t, onNewMessageUnfocused,
}) => {
  const interlocutor = livechatRoom?.participants.find((p) => p.userUid !== userUid);

  const [closeConfirm, setCloseConfirm] = useState<boolean>(false);
  const [userSentMessage, setUserSentMessage] = useState<boolean>(false);

  useEffect(() => {
    setUserSentMessage(!!messages.find((m) => m.origin === userUid));
  }, [messages]);

  const renderCountDown: CountdownRendererFn = ({
    hours, minutes, seconds,
  }) => (
    <span className="whitespace-nowrap font-semibold">
      {`${(hours).toLocaleString("fr-CH", { minimumIntegerDigits: 2 })}${t("common:hourLetter")} 
      ${minutes.toLocaleString("fr-CH", { minimumIntegerDigits: 2 })}${t("common:minuteLetter")} 
      ${seconds.toLocaleString("fr-CH", { minimumIntegerDigits: 2 })}${t("common:secondLetter")}`}
    </span>
  );

  const HeaderActions = useCallback(() => (
    <>
      {livechatRoomIsOpen(status) && (
        <div key="header">
          <Button theme="blue" size="medium" data-pw="liveChatFinish" onClick={() => setCloseConfirm(true)}>{t("finishLivechat")}</Button>
          <AlertModal
            open={closeConfirm}
            title={t("livechat.finishLivechatAlertTitle")}
            validateLabel={t("finishLivechat")}
            cancelLabel={t("common:cancel")}
            variant="warning"
            onValidate={() => {
              closeLivechatRoom();
              setCloseConfirm(false);
            }}
            onClose={() => setCloseConfirm(false)}
          >
            {t("finishLivechatAlertContent", { pseudo: interlocutor.name })}
          </AlertModal>
        </div>
      )}
    </>
  ), [status, closeLivechatRoom, t, interlocutor.name, setCloseConfirm, closeConfirm]);

  const MessageListContainer = useCallback(({ children }) => (
    <>
      {status === "CREATED" ? (
        <div className="flex items-center justify-center h-full w-full" key="container">
          <div className="border border-gray-200 shadow-sm rounded-md py-3 px-5">
            <div className="flex-row space-y-2">
              <div className="flex items-center justify-center space-x-4">
                <div><div className="w-2 h-2 rounded-full bg-red-600 animate-pulse" /></div>
                <div className="text-center">{t("livechatParticipantNotConnected", { name: interlocutor.name })}</div>
              </div>
              <div className="flex items-center justify-center space-x-1">
                <div>{t("timeBeforeExpire")}</div>
                <div><Countdown key={dayjs(livechatRoom.createdAt).add(livechatTimeoutDelay, "minute").diff(dayjs())} date={dayjs(livechatRoom.createdAt).add(livechatTimeoutDelay, "minute").toDate()} renderer={renderCountDown} /></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {children}
        </>
      )}
    </>
  ), [status, t, interlocutor.name]);

  const Indicator = useCallback(() => (
    <>
      {!userSentMessage && ["CONNECTED", "INITIATED"].includes(status) && (
        <div className="flex-row mb-2 ml-5 text-sm text-orange-500" data-pw="liveChatTimeoutIndication" key="indc">
          <div>{t("livechatTimeoutIndication")}</div>
          <div className="flex space-x-1">
            <div>
              {t("timeBeforeExpire")}
              <Countdown key={dayjs(livechatRoom.createdAt).add(livechatTimeoutDelay, "minute").diff(dayjs())} date={dayjs(livechatRoom.createdAt).add(livechatTimeoutDelay, "minute").toDate()} renderer={renderCountDown} />
            </div>
          </div>
        </div>
      )}
    </>
  ), [userSentMessage, livechatRoom.createdAt, status]);

  return (
    <>
      <Chat.ChatView
        enterShouldSend
        messages={messages ?? []}
        participants={livechatRoom.participants}
        participantsWriting={participantsWriting}
        onSendMessage={async (c) => sendMessage(c)}
        allowAttachments={showAttachment}
        onSendAttachment={sendAttachment}
        onNewMessageUnfocused={onNewMessageUnfocused}
        onTyping={(v) => sendWritingStatus(v)}
        userUid={userUid}
        getAttachment={getAttachment}
        showHeader={showHeader}
        showUsername
        t={t}
        active={livechatRoomIsOpen(status)}
        disabled={!online}
        slots={{
          messageListContainer: MessageListContainer,
          indicator: Indicator,
        }}
        headerSlots={{
          action: HeaderActions,
        }}
        systemMessages={[
          ...((status === "FINISHED" || status === "CANCELED") ? [
            {
              content: t("livechatFinished", { pseudo: interlocutor.name, at: DateUtils.from(livechatRoom.endDate).formatDateTime() }),
              dataPw: "liveChatFinished",
            }] : []
          ),
          ...((status === "EXPIRED") ? [
            {
              content: t("livechatExpired", { pseudo: interlocutor.name, at: DateUtils.from(livechatRoom.endDate).formatDateTime() }),
              dataPw: "liveChatExpired",
            },
          ] : []),
        ]}
      />
    </>
  );
};
