/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import { DateUtils } from "@audacia-hq/shared/utils";
import React, { memo, useEffect } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";

import AlertModal from "../modal/AlertModal";

import AttachmentMessage, { AttachmentMessageProps } from "./AttachmentMessage";

export interface MessagePayload {
  /** The type of the message payload */
  kind?: "text" | "attachment";
  /** The message content */
  content: string;
}
export interface MessageProps {
  /** The message content */
  payload: MessagePayload;
  /** The message author (display name) */
  pseudo: string;
  /** Is the user the author of the message? */
  primary?: boolean;
  /** The message timestamp */
  timestamp: Date | number;
  /** Show the date? */
  showDates?: boolean;
  /** Show deleted? */
  showDeleted?: boolean;
  /* Always show username, even if it's the current user */
  showUsername?: boolean;
  /** Delete callback. Button is automatically displayed if this is provided */
  onDelete?: () => void;
  dataPw?: string;
  id?: string;
  /** Original UID. Only really passed to slots.deleteAction */
  messageUid?: string;

  /** Attachment getter */
  getAttachment?: AttachmentMessageProps["getAttachment"];

  /** Translations */
  t: (key: string, params?: any) => string;

  /** Is deleted? */
  deleted?: boolean;

  /** Refusal reason */
  refused?: string;

  showReadReceipt?: boolean;

  read?: boolean;

  slots?: {
    /** Overrides the delete button render. This will only render if onDelete is provided. */
    deleteAction: (props: React.PropsWithChildren<{
      id: string;
    }>) => React.ReactNode;
  }
}

const RenderChildren = memo(({ children }: React.PropsWithChildren) => children);
const RenderNothing = memo(() => <></>);

const displayMessage = (deleted: boolean, refused: string, showDeleted: boolean, primary: boolean) => {
  if (showDeleted) return true;
  if (deleted) return false;
  if (refused && !primary) return false;
  return true;
};

/**
 * For messages sent by users. If you need to display messages sent by the
 * system, use the `SystemMessage` component.
 */
const Message: React.FC<MessageProps> = ({
  payload, pseudo, primary, timestamp, getAttachment, onDelete, dataPw, t, showDates, deleted,
  refused, showReadReceipt, showDeleted, showUsername, read, id, messageUid,
  slots: {
    deleteAction: DeleteAction = RenderChildren,
  } = {},
}) => {
  const [deleteConfirm, setDeleteConfirm] = React.useState<boolean>(false);

  return (
    <div
      data-pw="messages"
      id={id}
      key={`${(id ?? timestamp)}-${payload.kind}`}
      className={`flex my-1 justify-start items-center ${primary ? "flex-row-reverse " : ""}`}
    >
      <div
        className={
          clsx("flex flex-col gap-y-1", payload.kind === "attachment" ? "w-[80%] max-w-[50vh]" : "w-fit max-w-[80%]")
        }
      >
        <div className={clsx("text-sm", primary && "self-end")}>
          {primary && !showUsername ? t("you") : pseudo}
        </div>
        <div
          className={clsx(
            "p-3 rounded-b-xl flex",
            primary ? "bg-primary-900 rounded-l-xl" : "bg-gray-50 border border-black border-opacity-10 rounded-r-xl",
          )}
          data-pw={primary ? "primaryMessages" : "secondaryMessages"}
        >
          <div className="flex-1 w-full break-words text-wrap" style={{ wordBreak: "break-word" }}>

            {payload.kind === "text" && displayMessage(deleted, refused, showDeleted, primary) && (
              <span
                key={payload.content}
                className={clsx(
                  "whitespace-pre-wrap break-words relative w-full",
                  primary && "text-white",
                  !primary && "text-gray-800",
                )}
                data-pw="messageContent"
                dangerouslySetInnerHTML={{ __html: payload.content?.trim() }}
              />
            )}
            {payload.kind === "attachment" && displayMessage(deleted, refused, showDeleted, primary) && (
              <div className="mb-2">
                <AttachmentMessage
                  key={payload.content}
                  message={{
                    ...payload,
                    type: "message",
                  }}
                  viewImageText={t("messages:viewImage")}
                  imageTitleText={t("messages:viewImageTitle", { pseudo })}
                  getAttachment={getAttachment}
                />
              </div>
            )}

            {
              payload.kind === "text" && payload.content && displayMessage(deleted, refused, showDeleted, primary) && <br />
            }

            {(refused && !deleted) && (
              <span
                className={clsx(
                  "whitespace-pre-wrap break-words relative w-full",
                  primary && "text-red-200",
                  !primary && "text-red-600",
                )}
                // eslint-disable-next-line no-nested-ternary
                data-pw={dataPw || "messageRefusalReason"}
                // eslint-disable-next-line no-nested-ternary
                dangerouslySetInnerHTML={{
                  __html: primary
                    ? (refused === "refused" ? t("messages:defaultRefusalReason") : refused)
                    : t("messages:defaultRefusalReason"),
                }}
              />
            )}

            {(deleted) && (
              <span
                className={clsx(
                  "whitespace-pre-wrap break-words relative w-full",
                  primary && "text-red-200",
                  !primary && "text-red-600",
                )}
                // eslint-disable-next-line no-nested-ternary
                data-pw={dataPw || "messageDeleted"}
                // eslint-disable-next-line no-nested-ternary
                dangerouslySetInnerHTML={{ __html: t("messages:messageWasDeleted") }}
              />
            )}

            <div className={
              clsx(
                "text-xs text-right -mr-1 mt-1 flex justify-end space-x-3",
                primary ? "text-white opacity-80" : "text-gray-600",
              )
            }
            >
              <span>{showDates ? DateUtils.from(timestamp.valueOf()).formatDateTime() : DateUtils.from(timestamp.valueOf()).formatTime()}</span>
              {showReadReceipt && primary && (
                <div
                  className="text-xs space-x-1"
                  data-pw={
                    read ? "messageRead" : "messageUnread"
                  }
                >
                  <FontAwesomeIcon icon={read ? faCheckDouble : faCheck} />
                  <span>{read ? t("common:read") : t("sent")}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid">
          {onDelete && (
            <div className="justify-self-start">
              <DeleteAction id={messageUid}>
                <button
                  style={{ cursor: "pointer" }}
                  data-pw="messageDelete"
                  className="text-gray-500 text-sm hover:text-gray-700 mt-1"
                  type="button"
                  onClick={() => setDeleteConfirm(true)}
                >
                  {t("common:delete")}
                </button>
              </DeleteAction>
              <AlertModal
                open={deleteConfirm}
                title={t("messages:deleteTitle")}
                validateLabel={t("common:delete")}
                cancelLabel={t("common:cancel")}
                variant="warning"
                onValidate={() => {
                  onDelete();
                  setDeleteConfirm(false);
                }}
                onClose={() => setDeleteConfirm(false)}
              >
                {t("messages:deleteConfirm")}
              </AlertModal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
