import React, {
  useRef, useEffect, useState, memo,
  useCallback,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { MessagePayload, MessageProps } from "./Message";
import { MessageInputProps } from "./MessageInput";
// eslint-disable-next-line import/no-cycle
import Chat from ".";
import { HeaderProps } from "./Header";

/**
 * Built to be compatible with LivechatMessageItem
 */
export interface ChatViewMessage {
  uid?: string;
  origin: string;
  ts: number;
  payload: MessagePayload;
  deleted?: boolean;
  refused?: string;
  dataPw?: string;
  viewedAt?: string; // Date string
}

/**
 * Built to be compatible with LivechatRoomParticipant
 */
export interface ChatViewParticipant {
  name: string;
  userUid: string;
  picture?: string;
}

export interface ChatViewProps {
  /**
   * Is this chat currently active (i.e. liveroom is active).
   * If false, no message input box will be displayed.
   */
  active?: boolean | undefined;
  /** Should we show the header */
  showHeader?: boolean;
  /** Show dates? */
  showDates?: boolean;
  /** Hide status indicator */
  hideStatusIndicator?: boolean;
  /** Show deleted messages */
  showDeleted?: boolean;
  /** Should the message input send on enter? */
  enterShouldSend?: boolean;
  /** Messages */
  messages: ChatViewMessage[];
  /** System messages at the end */
  systemMessages?: string[] | { dataPw: string, content: string, level?: string }[];
  /** Participants */
  participants: ChatViewParticipant[];
  /** Who's writing? Used for indicators */
  participantsWriting: string[];
  /** Disable the input? */
  disabled?: boolean;
  /** Disable notifications? */
  disableNotifications?: boolean;
  /** Callback to send a message */
  onSendMessage: MessageInputProps["onSendMessage"];
  /** Callback to send a typing indicator */
  onTyping: MessageInputProps["onTyping"];
  /** Allow attachments? */
  allowAttachments?: boolean;
  /** Callback to send an attachment */
  onSendAttachment?: MessageInputProps["onSendAttachment"];
  inputPlaceholder?: MessageInputProps["placeholder"];
  inputButtonText?: MessageInputProps["buttonText"];
  /** Callback to notify of new messages when the window is not focused. Removes responsability from parent to perform actions */
  onNewMessageUnfocused?: () => void;
  /** If the top is reached, allow to fetch more messages, or just ignore */
  onEndReached?: () => void;
  /** On delete */
  onDelete?: (message: ChatViewMessage) => void;
  /** Should allow deletion (boolean or callback to return boolean) */
  allowDeletion?: boolean | ((message: ChatViewMessage) => boolean);
  /** Show read receipts */
  showReadReceipts?: boolean;
  /** Always show username */
  showUsername?: boolean;
  /** Current user (used to determine if the user is the author) */
  userUid: string;
  /** Attachment fetcher */
  getAttachment: MessageProps["getAttachment"];
  /** Translation */
  t: (key: string, params?: any) => string;

  /** Extra components for customisation. All slots MUST be memoized or it will cause render issues */
  slots?: {
    /** A component to display before the message input, after the typing indicator. Usually used for warnings. */
    indicator?: () => React.ReactNode;
    /** Wrap or replace the message list */
    messageListContainer?: (props: React.PropsWithChildren) => React.ReactNode;
    /** Surround the message input box with something else */
    messageInputContainer?: (props: React.PropsWithChildren) => React.ReactNode;
  }

  headerSlots?: HeaderProps["slots"];
  messageSlots?: MessageProps["slots"];
}

const RenderChildren = memo(({ children }: React.PropsWithChildren) => children);
const RenderNothing = memo(() => <></>);

/**
 * A component that displays the chat view. This controls:
 * - the header
 * - the message display
 * - the message input field
 *
 * and presents:
 * - any warning indicators for the user around the message box
 * - any typing indicators
 * - any system messages
 * - messages
 * - cancel/endchat button
 *
 * @warning It is your responsability to:
 * - handle the actual sending of messages
 * - handle the actual sending/receiving of typing indicators
 * - any notification sounds for new messages
 */
const ChatView: React.FC<ChatViewProps> = ({
  active,
  messages,
  systemMessages,
  participants,
  participantsWriting,
  onSendMessage,
  onTyping,
  allowAttachments,
  onSendAttachment,
  inputPlaceholder,
  inputButtonText,
  onEndReached,
  onDelete,
  allowDeletion,
  showReadReceipts,
  userUid,
  getAttachment,
  showHeader,
  showDates,
  showUsername,
  enterShouldSend,
  hideStatusIndicator,
  showDeleted,
  disabled,
  disableNotifications,
  t,
  onNewMessageUnfocused,
  slots: {
    indicator: SlotIndicator = RenderNothing,
    messageListContainer: SlotMessageListContainer = RenderChildren,
    messageInputContainer: SlotMessageInputContainer = RenderChildren,
  } = {},
  headerSlots,
  messageSlots,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const interlocutor = participants.find((p) => p.userUid !== userUid);

  const [tabFocus, setTabFocus] = useState(true);
  const [attachmentCache, setAttachementCache] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scrollTo({ top: containerRef.current.scrollHeight });
  }, [messages]);

  useEffect(() => {
    const cbTrue = () => setTabFocus(true);
    const cbFalse = () => setTabFocus(false);

    window.addEventListener("focus", cbTrue);
    window.addEventListener("blur", cbFalse);

    return () => {
      window.removeEventListener("focus", cbTrue);
      window.removeEventListener("blur", cbFalse);
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) return () => undefined;

    const scrollListener = () => {
      if (!containerRef.current) return;
      const minScrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight - 10;
      if (containerRef.current.scrollTop < -minScrollTop && onEndReached) {
        onEndReached();
      }
    };

    containerRef.current.addEventListener("scroll", scrollListener);

    return () => {
      containerRef.current?.removeEventListener("scroll", scrollListener);
    };
  }, [containerRef.current, onEndReached]);

  useEffect(() => {
    if (messages && !tabFocus && active) {
      if (onNewMessageUnfocused) onNewMessageUnfocused();
      if (!disableNotifications) {
        const audio = new Audio("/assets/message.mp3");
        audio.load();
        audio.play().catch(() => ({}));
      }
    }
  }, [messages]);

  const getAttachmentWithCache = useCallback(async (url: string, signal?: AbortSignal) => {
    if (attachmentCache.has(url)) return attachmentCache.get(url);
    const res = await getAttachment(url, signal);
    attachmentCache.set(url, res);
    return res;
  }, [attachmentCache]);

  const getPseudo = (uid: string) => participants.find((i) => i.userUid === uid)?.name;

  const getSortedMessage = useCallback(() => [...messages]
    .sort((a, b) => b.ts - a.ts)
    .reduce((acc, curr) => {
      // Ensure attachments come after their parent message
      if (acc.length === 0) return [[curr]];
      const last = acc[acc.length - 1];

      if (last.length === 0) return [...acc, [curr]];
      const lastMessage = last[last.length - 1];

      if (lastMessage.origin === curr.origin) {
        if (lastMessage.payload.kind === "attachment") {
          return [...acc.slice(0, -1), [...last, curr]];
        }
        return [...acc.slice(0, -1), [curr, ...last]];
      }

      return [...acc, [curr]];
    }, [] as ChatViewMessage[][])
    .flat()
    // Re-sort
    .sort((a, b) => b.ts - a.ts), [messages]);

  return (
    <div className="h-full flex flex-col">
      <div className="flex-none">
        {showHeader && (
          <Chat.Header pseudo={interlocutor.name} picture={interlocutor.picture} active={active} hideIndicator={hideStatusIndicator} slots={headerSlots} />
        )}
      </div>
      <div
        className="overscroll-y-contain overflow-y-auto flex-1 flex flex-col-reverse  w-full px-3 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
        // ref={containerRef}
        id="chat-container"
        key="messages"
      >
        {systemMessages?.map((message) => (
          <div key={`key-${message.dataPW}`}>
            { /* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Chat.SystemMessage key={message.dataPW} level={message.level} {...(typeof message === "string" ? {} : { "data-pw": message.dataPw })}>
              {
                typeof message === "string" ? message : message.content
              }
            </Chat.SystemMessage>
          </div>
        ))}
        { SlotMessageListContainer && (
          <SlotMessageListContainer>
            <InfiniteScroll
              dataLength={messages.length}
              next={onEndReached}
              hasMore
              loader={<></>}
              scrollableTarget="chat-container"
              inverse
              style={{ display: "flex", flexDirection: "column-reverse" }}
            >
              {[...messages].sort((a, b) => b.ts - a.ts).map((message, i) => (
                <Chat.Message
                  key={`${(message.uid || message.ts)}-${message.payload.kind}-${i}`}
                  id={`${(message.uid || message.ts)}-${message.payload.kind}-${i}`}
                  showDates={showDates}
                  showDeleted={showDeleted}
                  showUsername={showUsername}
                  deleted={message.deleted}
                  dataPw={message.dataPw}
                  payload={message.payload}
                  pseudo={getPseudo(message.origin)}
                  primary={userUid === message.origin}
                  refused={message.refused}
                  timestamp={message.ts}
                  getAttachment={getAttachmentWithCache}
                  onDelete={!message.deleted && !message.refused && ((typeof allowDeletion === "function" && allowDeletion?.(message)) || allowDeletion) ? () => onDelete?.(message) : undefined}
                  t={t}
                  read={!!message.viewedAt}
                  showReadReceipt={showReadReceipts}
                  slots={messageSlots}
                  messageUid={message.uid}
                />
              ))}
            </InfiniteScroll>
          </SlotMessageListContainer>
        )}

        {!SlotMessageListContainer && (
          <InfiniteScroll
            dataLength={messages.length}
            next={onEndReached}
            hasMore
            loader={<></>}
            scrollableTarget="chat-container"
            inverse
            style={{ display: "flex", flexDirection: "column-reverse" }}
          >
            {getSortedMessage().map((message, i) => (
              <Chat.Message
                key={`${(message.uid || message.ts)}-${message.payload.kind}-${i}`}
                id={`${(message.uid || message.ts)}-${message.payload.kind}-${i}`}
                showDates={showDates}
                showDeleted={showDeleted}
                showUsername={showUsername}
                deleted={message.deleted}
                dataPw={message.dataPw}
                payload={message.payload}
                pseudo={getPseudo(message.origin)}
                primary={userUid === message.origin}
                refused={message.refused}
                timestamp={message.ts}
                getAttachment={getAttachmentWithCache}
                onDelete={!message.deleted && !message.refused && ((typeof allowDeletion === "function" && allowDeletion?.(message)) || allowDeletion)
                  ? () => onDelete?.(message) : undefined}
                t={t}
                read={!!message.viewedAt}
                showReadReceipt={showReadReceipts}
                slots={messageSlots}
              />
            ))}
          </InfiniteScroll>
        )}

      </div>
      {active && (
        <div className="flex-none w-full">
          <div className="flex flex-col bg-gray-50 px-2 pt-2 pb-2 mt-2">
            <div className="text-sm">
              {[...new Set(participantsWriting)].map((i) => <Chat.TypingIndicator pseudo={getPseudo(i)} t={t} />)}
            </div>
            <div className="pt-2">
              <SlotIndicator />
            </div>
            <SlotMessageInputContainer key="input">
              <Chat.MessageInput
                placeholder={inputPlaceholder}
                buttonText={inputButtonText}
                onSendMessage={onSendMessage}
                onSendAttachment={onSendAttachment}
                onTyping={onTyping}
                disabled={disabled}
                enterShouldSend={enterShouldSend}
                allowAttachments={allowAttachments}
              />
            </SlotMessageInputContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatView;
