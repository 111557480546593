import { TFunction } from "i18next";
import { TFunction as NextTFunction } from "next-i18next";

export interface TimeStringOptions {
  signed?: boolean; // default true
  partial?: boolean; // default false
  padded?: boolean; // default true
  tokens?: ("hour" | "minute" | "second")[]; // defaults to all
  truncate?: boolean; // default false
}

export default class HMS {
  private sign: string;
  private h: number;
  private m: number;
  private s: number;

  constructor(seconds: number) {
    const abs = Math.abs(seconds);
    this.sign = seconds < 0 ? "-" : "";
    this.h = Math.floor(abs / 3600);
    this.m = (Math.floor(abs / 60) % 60);
    this.s = (abs % 60);
  }

  isNegative(): boolean {
    return this.sign === "-";
  }
  toSeconds(): number {
    return (this.sign?.length ? -1 : 1) * (
      this.h * 3600 + this.m * 60 + this.s
    );
  }

  toTime = () => `${this.h.toString().padStart(2, "0")}:${this.m.toString().padStart(2, "0")}:${this.s.toString().padStart(2, "0")}`;

  toTimeString(t: TFunction | NextTFunction, options?: TimeStringOptions) : string {
    const tokens = [
      {label: "hour", value: this.h},
      {label: "minute", value: this.m},
      {label: "second", value: this.s}
    ];
    const startIdx = options?.partial ? tokens.findIndex(({label, value}) => value > 0 || label === "second") : 0;

    return ((options?.signed === false) ? "" : this.sign)
      + tokens
        .slice(startIdx)
        .filter((t) => !options?.tokens || (options.tokens as string[]).includes(t.label))
        .filter((t) => options?.truncate ? t.value > 0 : true)
        .map(({label, value}, idx) => (t as any)(`common:hms.${label}`, {
          value: (!idx && options?.padded === false) ? value.toString() : value.toString().padStart(2, "0")
        }))
        .join(" ")
  }

}