import React, { useEffect, useRef, useState } from "react";
import { LivechatMessage, useLayout } from "@audacia-hq/shared/contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";

import Modal from "../modal/Modal";

export interface AttachmentMessageProps {
  message: LivechatMessage;
  getAttachment: (url: string, signal?: AbortSignal) => Promise<string>;
  viewImageText?: string;
  imageTitleText?: string;
}

const AttachmentMessage: React.FC<AttachmentMessageProps> = ({
  message, getAttachment, viewImageText, imageTitleText,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string>();
  const [view, setView] = useState(false);

  useEffect(() => {
    if (!message || message.kind !== "attachment") return () => { /* empty */ };
    let ac = new AbortController();
    setLoading(true);
    getAttachment(message.content, ac.signal).then((d) => {
      setData(d);
      setLoading(false);
      // No need to keep the controller after the request is done
      ac = null;
    }).catch(() => ({}));

    return () => {
      // Attempt to avoid a ridiculous number of requests
      ac?.abort("Constraint: Unmounted component before attachment was done fetching");
    };
  }, [message, getAttachment]);

  return (
    <div className="relative rounded-lg">
      {data && !loading && (
        <>
          <Modal
            title={imageTitleText}
            show={view}
            onClose={() => setView(false)}
            size="large"
            mobileFullscreen
          >
            <div className="h-full">
              <img src={data} alt="attachments" className="object-contain w-full h-full max-h-full" />
            </div>
          </Modal>
          <button
            type="button"
            onClick={() => setView(true)}
            className="rounded-lg absolute z-9 w-full h-full backdrop-blur-sm bg-black/30 opacity-0 transition-opacity duration-150 hover:opacity-100 flex items-center justify-center"
          >
            <div className="text-white text-xl flex flex-col gap-y-1 ">
              <FontAwesomeIcon icon={faEye} />
              <span className="text-sm">{viewImageText}</span>
            </div>
          </button>
          <img src={data} alt="attachment" className="rounded-lg aspect-video w-full object-cover" />
        </>
      )}
    </div>
  );
};

export default AttachmentMessage;
