import React, {
  useState, useRef, useEffect,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";

import AttachmentPanel from "../livechat/AttachmentPanel";
import Button from "../Button";

export interface MessageInputProps {
  onSendMessage?: (content: string) => Promise<void>;
  onSendAttachment?: (file: File) => Promise<void>;
  onTyping?: (isTyping: boolean) => void;
  allowAttachments?: boolean;
  disabled?: boolean;
  fixedHeight?: boolean;
  enterShouldSend?: boolean;
  placeholder?: string;
  buttonText?: string;
}

/**
 * A component that allows the user to write a message, add an attachment, etc.
 * This controls all related dialogs and actions. The callee must actually send the message and typing indicators.
 */
const MessageInput: React.FC<MessageInputProps> = ({
  allowAttachments, onSendAttachment, onSendMessage, onTyping, disabled, fixedHeight, enterShouldSend,
  placeholder = "Message", buttonText,
}) => {
  // eslint-disable-next-line no-undef
  const [writingTimer, setWritingTimer] = useState<NodeJS.Timeout>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<{ file: File, url: string }>();

  const heightLimit = 200;
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (textInputRef.current && !fixedHeight) {
      textInputRef.current.style.height = "auto";
      textInputRef.current.style.setProperty("height", `${Math.min(textInputRef.current.scrollHeight, heightLimit)}px`, "important");
      // textInputRef.current.style.height = ;
    }
  };

  const handleInputChange = (value: string) => {
    formik.setFieldValue("msg", value);
    onTyping?.(true);
    clearTimeout(writingTimer);

    setWritingTimer(setTimeout(() => {
      onTyping?.(false);
    }, 2000));
  };

  const formik = useFormik<{ msg: string }>({
    initialValues: {
      msg: "",
    },
    validationSchema: Yup.object().shape({
      msg: Yup.string().required(),
    }),
    onSubmit: (form) => {
      onSendMessage?.(form.msg);
      onTyping?.(false);
      clearTimeout(writingTimer);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (textInputRef) adjustHeight();
  }, [formik.values.msg]);

  return (
    <>
      <AttachmentPanel
        show={file !== undefined}
        url={file?.url}
        onSend={() => onSendAttachment?.(file.file).then(() => { setFile(undefined); })}
        onClose={() => {
          setFile(undefined);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
      />
      <form onSubmit={formik.handleSubmit} className="w-full p-2 flex space-x-2 items-end">
        {allowAttachments && (
          <div>
            <button
              data-pw="liveChatAttachmentSend"
              type="button"
              aria-label="attachment"
              className="hover:text-primary-200 text-primary-500 w-10 h-10 transition-all duration-150"
              onClick={() => { fileInputRef.current.click(); }}
            >
              <FontAwesomeIcon icon={faPaperclip} />
            </button>
            <input
              data-pw="messageFile"
              type="file"
              className="hidden"
              onChange={(e) => setFile({ file: e.target.files[0], url: URL.createObjectURL(e.target.files[0]) })}
              ref={fileInputRef}
              accept=".png,.jpg,.webp,.gif"
            />
          </div>
        )}
        {!disabled && (
          <>
            <div className="w-full">
              <textarea
                ref={textInputRef}
                data-pw="messageInput"
                placeholder={placeholder}
                className="
                  resize-none block
                  relative w-full
                  bg-white text-black rounded-lg p-2 focus:outline-none whitespace-pre-wrap break-words border border-gray-300"
                name="msg"
                autoComplete="off"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                key="input"
                value={formik.values.msg}
                onChange={(v) => handleInputChange(v.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey && enterShouldSend) {
                    e.preventDefault();
                    formik.submitForm();
                  }
                }}
              />
            </div>
            <div>
              {buttonText
                ? (
                  <Button
                    data-pw="messageSend"
                    type="submit"
                    className="!px-3 !py-3"
                  >
                    {buttonText}
                  </Button>
                )
                : (
                  <button
                    data-pw="messageSend"
                    type="submit"
                    aria-label="send"
                    className="rounded-full text-white hover:bg-primary-200 bg-primary-500 w-10 h-10 transition-all duration-150"
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                )}
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default MessageInput;
