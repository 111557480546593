import { Buffer } from "buffer";

import axios from "../axios";
import config from "../config";

export function deleteFile(fileUrl: string) {
  return axios.delete(`${config.api.storageBaseHost}/file/${fileUrl}`);
}

export function getFile(file: string) {
  return axios.get(`${config.api.storageBaseHost}/file/${file}`, {
    responseType: "arraybuffer",
  }).then((res) => `data:${
    res.headers["content-type"]
  };base64,${Buffer.from(res.data, "binary").toString("base64")}`);
}

export const uploadPrivateFile = async (file: File, filename: string, context: string, shared?: string[]) => {
  const formData = new FormData();
  formData.append("file", file, filename);
  formData.append("context", context);
  if (shared) formData.append("shared", JSON.stringify(shared));
  const res = await axios.post<{ url: string; }>(
    `${config.api.storageBaseHost}/file/private`,
    formData,
    {
      headers: {
        "x-application-context": "idealvoyance",
      },
    },
  );
  return res.data.url;
};

export function getPrivateFile(file: string, signal?: AbortSignal) {
  return axios.get(`${config.api.storageBaseHost}/file/${file}`, {
    responseType: "arraybuffer",
    signal,
  }).then((res) => `data:${
    res.headers["content-type"]
  };base64,${Buffer.from(res.data, "binary").toString("base64")}`);
}
