import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowRightLong, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
// antd badge
import { Badge } from "antd";

import TextAvatar from "../TextAvatar";

import { MessageSummary, Correspondent } from "./models";

interface SidebarProps {
  messagesSummary: MessageSummary[];
  correspondents: Correspondent[];
  sidebarVisible: boolean;
  loading?: boolean;
  selectedCorrespondentUid?: string;
  handleSelectCorrespondent: (correspondentUid: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  messagesSummary,
  correspondents,
  sidebarVisible,
  loading,
  selectedCorrespondentUid,
  handleSelectCorrespondent,
}) => (
  <div className={clsx(sidebarVisible ? "flex flex-col justify-start basis-auto h-full w-full max-w-full" : "hidden")}>
    {messagesSummary.map((messageSummary, index) => {
      const correspondent = correspondents.find(
        (e) => e.uid === messageSummary.recipient,
      );

      if (!correspondent) return null;

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          key={correspondent.uid}
          data-pw="messagesProfil"
          className={
            clsx(
              "flex flex-row p-4 hover:cursor-pointer hover:bg-blue-50 justify-between w-full items-center border-b border-gray-300",
              correspondent.uid === selectedCorrespondentUid && "bg-blue-50",
            )
          }
          onClick={() => handleSelectCorrespondent(correspondent.uid)}
        >
          {correspondent?.picture ? (
            <img className="w-8 h-8 rounded-full mr-4" src={correspondent?.picture} alt={correspondent.name} />
          ) : (
            <TextAvatar
              text={correspondent?.name}
              className="mr-2 w-8 h-8"
              colorSeed={correspondent.uid}
            />
          )}
          <div className="flex justify-between w-full items-center h-full overflow-hidden">

            <div className="text-ellipsis whitespace-nowrap overflow-hidden text-sm">{correspondent?.name}</div>
            <div className="flex flex-row items-center gap-2">
              {messageSummary.unreadMsg > 0 && (
                <Badge count={messageSummary.unreadMsg} className="ml-3" overflowCount={9} color="red" />
              )}
              <FontAwesomeIcon icon={faArrowRightLong} />
            </div>
          </div>
        </div>
      );
    })}
    {loading && (
      <div className="flex justify-center items-center py-4">
        <div className="text-center"><FontAwesomeIcon className="text-xl text-slate-500" icon={faCircleNotch} spin /></div>
      </div>
    )}
  </div>
);

export default Sidebar;
